import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '~components/layouts/Default';
import PageHeader from '~components/common/PageHeader';
import Container from '~components/common/Container';
import parse from 'html-react-parser';
import P from '~components/typography/P';
import FaqItem from '~components/common/FaqItem';
import Button from '~components/common/Button';
import GridTwo from '~components/common/GridTwo';

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Fragen und Antworten zu Autogenes Training und vieles mehr" desc="Wir beantworten die häufigsten Fragen zum Kurs Autogenes Training und Progressive Muskelentspannung" active="FAQ">
      <div className="bg-gray-50">
        <PageHeader title="FAQ" breadcrumb={[{ title: 'Wissen', link: '/wissen/' }, { title: 'FAQ' }]} />
        <Container noMargin className="my-8">
          <dl className="mx-auto max-w-2xl space-y-3">
            {data.allFaq.nodes.map((faq) => {
              return <FaqItem title={faq.frage} text={parse(faq.text)} key={faq.id} />;
            })}
          </dl>

          <GridTwo className="my-8">
            <div>
              <P className="mb-3">
                Auf unserer FAQ Seite beantworten wir dir Fragen zu unseren Kursen, unserem Seminarangebot und zum Downloadbereich. Bitte habe Verständnis dafür, dass wir den Bereich mit der Zeit aufbauen, je nachdem welche Fragen uns
                erreichen. Sollten wir also deine Frage nicht treffend beantwortet haben, dann schreib uns doch einfach eine E-Mail und wir nehmen die Frage in unseren Katalog mit auf.
              </P>
              <P className="mb-3">
                Manche Fragen sind so selten, oder auch so konkret, dass wir Sie gerne in einem persönlichen Gespräch beantworten. Dafür kannst du gerne unser{' '}
                <Link to="/kontakt/" className="underline">
                  Kontaktformular
                </Link>{' '}
                nutzen.{' '}
              </P>
              <P className="mb-3">Schreibe uns dein Anliegen so konkret wie möglich, sodass wir einen optimalen Support gewährleisten können.</P>
              <P className="mb-3">
                Bevorzugst du einen Kontakt übers Telefon? Dann buche dir gerne einen passenden Termin über{' '}
                <Link to="/termin/" className="underline">
                  Calendly
                </Link>
                , den findest du in dem du{' '}
                <Link to="/termin/" className="underline">
                  hier
                </Link>{' '}
                klickst. Beachte bitte auch, dass dir während eines Kurses der telefonische Support zur Verfügung steht. Pro Kurs kannst du ein 20-minütiges Telefonat mit uns buchen, in dem wir dich noch einmal individuell betreuen und
                deine Fragen beantworten. Dabei haben Fabian und Johannes sich auf unterschiedliche Bereiche spezialisiert.
              </P>
              <P className="mb-3">
                Für technische Fragen kannst du Fabian kontaktieren, der dir weiterhilft, wenn du bei Elopage Schwierigkeiten hast oder Unterstützung benötigst. Via Screensharing haben wir die Möglichkeit, dir deinen Weg zum Kurs zu
                erklären und sorgen so für ein unproblematisches Üben. Fabian ist außerdem dein Ansprechpartner, wenn du Fragen zu Kooperationen, Affiliate Programmen oder ähnlichem hast. Er ist mittlerweile ein echter Elopageprofi und
                erstellt seit vier Jahren Onlinekurse auf der Lernplattform.
              </P>
            </div>
            <div>
              <P className="mb-3">
                Inhaltliche Fragen beantwortet Johannes. Er ist Kursleiter für das Autogene Training, Progressive Muskelentspannung und Stressmanagement und leitet seit mehr als sieben Jahren Workshops, Seminare und Kurse in dem Bereich. Es
                gibt fast keine Frage, die nicht schon gestellt wurde, daher sind wir zuversichtlich, dass wir auch deine inhaltliche Frage beantworten können.{' '}
              </P>
              <P className="mb-3">
                Insbesondere wenn du nicht so recht entscheiden kannst, welches Angebot, das Richtige für dich ist, haben wir dir ein kleines Tool entwickelt. Wir sprechen von unserem{' '}
                <Link to="/kurse/kursquiz/" className="underline">
                  Kursquiz
                </Link>
                , dass dir eine kleine Entscheidungshilfe bietet.{' '}
              </P>
              <P>
                Das wichtigste zum Schluss. Unsere Kurse sind alle nach §20 SGB V zertifiziert, das bedeutet für dich, dass du mit uns einen qualitätsgeprüften Anbieter gefunden hast, dessen Kursangebot von den gesetzlichen Krankenkassen
                bezuschusst wird. Wie hoch die Förderung ausfällt, ist sehr unterschiedlich. Um dir den Zugang zu erleichtern und deine Frage auf die Förderhöhe zu beantworten, hat unser lieber Andreas Lautenschlager ein Tool entwickelt,
                dass du hier unter dem Krankenkassencheck findest. Gib einfach ein, bei wem du versichert bist und du erhältst deine Förderhöhe für das Jahr 2022.{' '}
              </P>
            </div>
          </GridTwo>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    allFaq {
      nodes {
        text
        frage
        id
      }
    }
    site {
      siteMetadata {
        contact {
          mail
        }
      }
    }
  }
`;
